.pagination-controls {
    display: flex;
    justify-content: center;
    margin-top: 24px;
}

.ngx-pagination {

    a {
        border-radius: 6px;
        font-size: 16px;
        line-height: 25.6px;
        letter-spacing: -0.32px;
        font-family: 'Poppins';

        &:hover {
            border-radius: 6px;
        }
    }

    .disabled {
        border-radius: 6px;
        font-size: 16px;
        line-height: 25.6px;
        letter-spacing: -0.32px;
        font-family: 'Poppins';
        opacity: 0.5;
    }

    .current {
        background-color: rgba(23, 124, 218, 0.3) !important;
        color: #177CDA !important;
        font-size: 16px;
        line-height: 25.6px;
        letter-spacing: -0.32px;
        font-family: 'Poppins';
        border-radius: 6px;
        padding: 2.8px 12px !important;
    }
}

.ngx-pagination {
    position: relative;

    .pagination-previous.disabled::before {
        content: url('https://svgshare.com/i/hLa.svg') !important;
        display: inline-block;
        margin-right: 0.5rem;
        color: #177CDA;
        position: absolute;
        top: 5px;
        left: 30px;
    }

    .pagination-previous a::before {
        content: url('https://svgshare.com/i/hLa.svg') !important;
        display: inline-block;
        margin-right: 0.5rem;
        color: #177CDA;
        position: absolute;
        top: 5px;
        left: 30px;
    }

    .pagination-next a::after,
    .pagination-next.disabled::after {
        content: url('https://svgshare.com/i/hLo.svg') !important;
        display: inline-block;
        margin-right: 0.5rem;
        color: #177CDA;
        position: absolute;
        top: 5px;
        right: -15px;
    }
}