//  // Color themes Map
// $themes: (
//   default: #666,
//   banana: #f1c40f,
//   cherry: #c0392b,
//   blueberry: #8e44ad,
//   leaf: #27ae60,
//   nightsky: #2980b9
// );
// // @param $name: name of the theme (HTML class)
// // @param $color: color of the theme
// @mixin theme($name, $color) {
//   .#{$name} {
//     .topbar-left , .navbar.navbar-default{
//       background : $color !important ;
//     }
//     a.logo{
//       color: darken($color, 30%) !important;
//     }
//   }
// }
// @each $key, $value in $themes {
//   @include theme($key, $value);
// }
// backgroundColor: #222,
// textColor: #ddd,
// buttonTextColor: #aaa,
// buttonTextTransform: uppercase,
// buttonTextHoverColor: #ddd,
// buttonColor: #333,
// buttonBorder: 1px solid #aaa
//#3c4752 , //424951
$successColor: #00A510;
$warningColor: #FC9031;
$dangerColor: #D84040;
$primary: #177CDA;
$themes: (
    dark: (placeholder:#98a6ad,
        formBorder: 1px solid rgba(255, 255, 255, 0.15),
        lines: 1px solid #273239,
        boxShadow: 2px solid #fff,
        ocrTextColor: white,
        backgroundSecondary: #41525D,
        backgroundTertiary: #2F3C45,
        headings: #F5F5F5,
        textLight: #FFFFFF, //#85868F,
        textGrey: #1E2026,
        textDark: #F5F5F5, //#6A6A6A,
        svgColor: #22252A,
        menulist:#35383F, //#282a2e,
        scrollBg:#22252A,
        primaryHeading: #FCFCFF,
        secondaryHeading: #E6E7F1,
        textHeader: #E6E7F1,
        textDetails: #BABCCC,
        primaryButtonBorder: 2px solid #41525D,
        secondaryButtonClickedBgColor: #273239,
        secondaryButtonBorder: 2px solid #273239,
        secondaryButtonHoverBorder: 1px solid #273239,
        secondaryButtonBgColor: #273239,
        secondaryButtonColor: #BABCCC,
        background: #273239,
        sidebar:#177CDA,
        sideMenuBg:#273239,
        cardBorder: 2px solid #273239,
        topBorder:4px solid #273239,
        inputBackground: #41525D,
        tableBorder:#30464E,
        tableHeader:#2F4145,
        accordionHeader:rgb(255 255 255 / 12%),
        workflowDragArea:#41525D,
        
    ),
    light: (placeholder:#98a6ad,
        formBorder: 1px solid rgba(0, 0, 0, 0.15),
        lines: 1px solid #E5E5E5,
        boxShadow: 2px solid #fff,
        ocrTextColor: black,
        backgroundSecondary: #FFFFFF,
        backgroundTertiary: #F8F8F8,
        headings: #3A3A3A,
        textLight: #1E2026, //#85868F,
        textGrey: #1E2026,
        textDark: #B4B4B4,
        svgColor: #FFFFFF,
        menulist:#fbfbfb,
        scrollBg:#ffffff,
        primaryHeading: #212126,
        secondaryHeading: #343536,
        textHeader: #343536,
        textDetails: #5F6368,
        primaryButtonBorder: 2px solid #FFFFFF,
        secondaryButtonBorder: 2px solid #E5E5E5,
        secondaryButtonHoverBorder: 1px solid #D2D2D2,
        secondaryButtonBgColor: #E5E5E5,
        secondaryButtonColor: #5F6368,
        secondaryButtonClickedBgColor: #D2D2D2,
        background: #E5E5E5,
        sidebar:#177CDA,
        sideMenuBg:#E5E5E5,
        cardBorder: 2px solid #E5E5E5,
        topBorder: 4px solid #E5E5E5,
        inputBackground: #FFFFFF,
        tableBorder:#343536,
        tableHeader:#F8F8F8,
        accordionHeader: #d1d3d5,
        workflowDragArea:#F1F1F1,
    ),
    golden: (background: #4f575f,
        backgroundSecondary: #2074c9,
        textDark: #f3f3f3,
        textMuted: #98a6ad,
        headings: #ffce61,
        formBorder: none,
        boxShadow: 2px solid #fff,
        ocrTextColor: black)
);

@mixin themify($themes: $themes) {

    @each $theme,
    $map in $themes {
        .theme-#{$theme} & {
            $theme-map: () !global;

            @each $key,
            $submap in $map {
                $value: map-get(map-get($themes, $theme), "#{$key}");
                $theme-map: map-merge($theme-map, ($key: $value)) !global;
            }

            @content;
            $theme-map: null !global;
        }
    }
}

@function themed($key) {
    @return map-get($theme-map, $key);
}

// Text Styles
.primary-heading {
    @include themify($themes) {
        color: themed('primaryHeading') !important;
    }
}

.secondary-heading {
    @include themify($themes) {
        color: themed('secondaryHeading') !important;
    }
}

.text-header,
.form-container span,
.detail-form-container h5,
.aml-card-container h5,
.aml-form-container h5,
.aml-card-detail-container h5,
.aml-detail-container h5,
.exceed-tabs .tab-item,
.multi-tabs .tab-item,
.table-bordered th,
.customSelect .select-text,
.customTextArea textarea,
.customInput .customInput__field {
    @include themify($themes) {
        color: themed('textHeader') !important;
    }

    &.active {
        @include themify($themes) {
            color: themed('background') !important;
            background-color: themed('textDetails');
        }
    }
}

.processLength span {
    @include themify($themes) {
        color: themed('textHeader');
    }
}

.text-details,
.text-snippet,
.form-container p,
.detail-form-container p,
.aml-card-container p,
.aml-card-detail-container p,
.aml-detail-container p,
.aml-form-container p,
.aml-details-card span,
.text-dropdownOption,
.do-form-group label,
.customSelect .select-label {
    @include themify($themes) {
        color: themed('textDetails') !important;
    }
}

.secondary-card, .progress-bar-info-alt {
    @include themify($themes) {
        background-color: themed('backgroundTertiary') !important;
    }
}

.customInput {
    &__label {
        @include themify($themes) {
            color: themed('textDetails') !important;
        }
    }

    &__field {
        &:focus {
            @include themify($themes) {
                border: 2px solid #177CDA !important;
                border-radius: 8px;
                box-shadow: unset !important;
            }

            &+.customInput__label {
                color: #177CDA !important;
            }
        }

        &:focus,
        &:not(:placeholder-shown) {
            &+.customInput__label {
                @include themify($themes) {
                    color: themed('textDetails');
                }
            }
        }
    }

    .icon-label {
        @include themify($themes) {
            color: themed('textDetails') !important;
        }
    }
}

.rowPageSelect {
    @include themify($themes) {
        border: themed('cardBorder') !important;
    }

    select {
        @include themify($themes) {
            color: themed('textDetails');
        }
    }
}

.table tr td {
    @include themify($themes) {
        color: themed('textDetails');
    }
}

.text-defaults {
    @include themify($themes) {
        color: themed('textDetails');
    }
}

// Button Styles
.primary-button {
    &:hover {
        @include themify($themes) {
            border: themed('primaryButtonBorder') !important;
        }
    }

    &:disabled {
        &:hover {
            border: none !important;
        }
    }
}

.secondary-button {
    @include themify($themes) {
        border: themed('secondaryButtonBorder') !important;
        color: #177CDA !important;
        background-color: themed('secondaryButtonBgColor') !important;
    }

    &:hover {
        @include themify($themes) {
            border: themed('secondaryButtonHoverBorder') !important;
        }
    }

    &:active {
        @include themify($themes) {
            border: themed('secondaryButtonClickedBgColor') !important;
        }
    }

    &:disabled {
        &:hover {
            border: none !important;
        }
    }
}

// Backgrounds
.content-page {
    @include themify($themes) {
        background-color: themed('background') !important;
    }
}

.navbar.navbar-default {
    @include themify($themes) {
        background-color: themed('backgroundSecondary') !important;
    }
}

// Ng Select
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    @include themify($themes) {
        background-color: themed('background') !important;
        color: themed('textDetails') !important;
    }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option,
.ng-select .ng-select-container {
    @include themify($themes) {
        background-color: themed('backgroundSecondary') !important;
        color: themed('textHeader') !important;
    }
}

.ng-select .ng-select-container .ng-value-container .ng-placeholder {
    @include themify($themes) {
        color: themed('textDetails') !important;
    }
}

.ngSelectBox {
    &__label {
        @include themify($themes) {
            background-color: themed('backgroundSecondary') !important;
            color: #177CDA !important;
        }
    }
}

// Ng Select
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    @include themify($themes) {
        background-color: themed('background') !important;
        color: themed('textDetails') !important;
    }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option,
.ng-select .ng-select-container {
    @include themify($themes) {
        background-color: themed('backgroundSecondary') !important;
        color: themed('textHeader') !important;
    }
}

// Borders
.primary-card,
.img-thumbnail,
.ng-select .ng-select-container,
.ng-select.ng-select-focused>.ng-select-container,
.ng-dropdown-panel.ng-select-bottom {
    @include themify($themes) {
        border: themed('cardBorder') !important;
    }
}

.kyc-border{
    border-radius: 12px;
    @include themify($themes) {
        border: themed('cardBorder') !important;
    }
}

.border-right {
    @include themify($themes) {
        border-right: themed('cardBorder') !important;
    }
}

.ng-select .ng-select-container {
    @include themify($themes) {
        border: themed('cardBorder') !important;

        &:active {
            border: 2px solid #177CDA !important;
        }
    }
}

.ng-select.ng-select-focused>.ng-select-container,
.ng-dropdown-panel.ng-select-bottom {
    border: 2px solid #177CDA !important;
}

.ngx-pagination {
    a {
        @include themify($themes) {
            color: themed('textDetails') !important;
        }

        &:hover {
            @include themify($themes) {
                background-color: themed('background') !important;
            }
        }
    }

    .disabled {
        @include themify($themes) {
            color: themed('textDetails') !important;
        }
    }
}

.topbar-left,
.dropdown-item:hover,
.activity-log-button .btn-activity-log,
.do-form-group input,
.do-form-group select,
.do-form-group-radio {
    @include themify($themes) {
        background-color: themed('background') !important;
    }
}

.customInput .customInput__label,
.customSelect .select-label,
.text-dropdownOption {
    @include themify($themes) {
        background-color: themed('backgroundSecondary') !important;
    }
}

.card-id-select p.id-name {
    @include themify($themes) {
        border-top: themed('cardBorder') !important;
        background-color: themed('background') !important;
    }
}

.customInput .customInput__field,
.customTextArea textarea,
.customSelect,.filter-button, .table-status-badge {
    @include themify($themes) {
        border: themed('cardBorder');
    }
}

.trade-table tr, .trade-table tr td{
    @include themify($themes) {
        border: themed('lines') !important;
    }
}

.detail-page-tabs {
    .tab-item {
      @include themify($themes) {
        color: themed('textDetails') !important;
      }
  
      &.active {
        color: $primary  !important;
        padding-bottom: 2px;
        border-bottom: 2px solid $primary  !important;
      }
    }
  
    @include themify($themes) {
      border-bottom: themed('cardBorder') !important;
    }
  }

.primary-chips {
    @include themify($themes) {
      border: themed('cardBorder') !important;
      color: themed('textDetails') !important;
      background-color: themed('backgroundTertiary') !important;
    }
  }
  
  .dialog-outline-buttons {
    @include themify($themes) {
      border-top: themed('cardBorder') !important;
      color: themed('textDetails') !important;
    }
  }

.table-bordered tr:hover td {
    @include themify($themes) {
        background-color: themed('background') !important;
    }
}

.radioButton{
    input[type="radio"]{
        @include themify($themes) {
            border: themed('cardBorder');
          }
    }
    input[type="radio"]::before{
        @include themify($themes) {
            background-color: themed('background');
        }
    }
}

.table thead th {
    @include themify($themes) {
        background-color: themed('tableHeader') !important;
    }
}

.table thead th {
    @include themify($themes) {
        border-bottom: 2px solid themed('tableBorder') !important
    }
}

.card-id-select {
    @include themify($themes) {
        border: themed('formBorder') !important;
    }
}

.vertical-line {
    @include themify($themes) {
      border-left: themed('cardBorder') !important;
    }
  }
  
.appBg,
.card-box,
.progress-white.progress-bar-info-alt,
.info-primary-card,
.text-transparent-button,
.primary-card,
.dropdown-menu,
.stickColumn,
.tr-pages,
#leftPaneSettings,
#leftPane,
.bg-light .modal .modal-dialog .modal-content,
.menubutton,
textarea[name="answer"] {
    @include themify($themes) {
        background-color: themed('backgroundSecondary') !important;
    }
}

.stickty-card-box{
    @include themify($themes) {
        background-color: themed('backgroundSecondary') !important;
        
    }
    &.top-card-border{
        @include themify($themes) {
            border-bottom: themed('lines');
        }
    }
}

.card-questions .input-field textarea {
    @include themify($themes) {
      background-color: themed('backgroundSecondary') !important;
      color: themed('textDetails') !important;
    }
  }

.filled-outline-button {
    @include themify($themes) {
      background-color: themed('backgroundTertiary') !important;
      border: themed('cardBorder');
  
      &:disabled {
        border: themed('primaryButtonBorder') !important;
        color: themed('textHeader') !important;
        cursor: not-allowed;
      }
  
      &:hover {
        border: 2px solid $primary;
      }
  
      &.non-highlight-button {
        color: themed('textHeader') !important;
      }
    }
}

.left-sidebar-menu,
.side-menu,
#sidebar-menu>ul>li>a,
.sidebarScroller {
    @include themify($themes) {
        background-color: themed('sidebar') !important;
    }
}

#sidebar-menu>ul>li>a {
    @include themify($themes) {
        border: 1px solid !important;
        border-color: rgba(themed('backgroundSecondary'), 0.12) !important;
    }

    &.subdrop {
        background-color: #5fb6f7 !important;
    }

    &:hover {
        background-color: #5fb6f7 !important;
    }
}

#sidebar-menu>ul>li>ul {
    li {
        &.active {
            @include themify($themes) {
                font-weight: bolder;
                background-color: #fff;
            }
        }

        &:hover {
            background-color: #5fb6f7 !important;
            color: #fff !important;
        }
    }
}

.emotion-analysis {
    .items {
        @include themify($themes) {
            background-color: themed('backgroundSecondary') !important;
        }
    }
}

.customCheckbox label:before {
    @include themify($themes) {
      border: 2px solid themed('textDetails');
    }
  }

.info-tabs {
    .tab-item-info {
        @include themify($themes) {
            background-color: themed('inputBackground') !important;
        }

        .fa {
            @include themify($themes) {
                background-color: themed('backgroundSecondary') !important;
            }
        }
    }
}

.aml-details-card {
    .fa {
        @include themify($themes) {
            background-color: themed('background') !important;
        }
    }
}

.risk-button {
    .btn-secondary {
        @include themify($themes) {
            background-color: themed('background') !important;
        }
    }
}

.table-bordered tr {
    .action {
        .items {
            @include themify($themes) {
                background-color: themed('background') !important;
            }
        }
    }
}

.form-control,
.form-container {
    @include themify($themes) {
        background-color: themed('inputBackground') !important;
        border: themed('cardBorder');
    }
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
.app-search button,
.form-control,
.logo,
.subTextVisible,
.button-menu-mobile,
#sidebar-menu>ul>li>a.active,
.notification-box ul li a,
.aml-form-container ul li {
    @include themify($themes) {
        color: themed('headings') !important;
    }
}

.default-text {
    @include themify($themes) {
        color: themed('headings');
    }
}

.do-form-group,
.do-form-group-radio,
.customInput {

    input,
    select,
    option {
        @include themify($themes) {
            color: themed('textHeader');
        }

        &:-webkit-autofill {
            @include themify($themes) {
                -webkit-text-fill-color: themed('textHeader');
            }
        }

        &:-webkit-autofill:focus {
            @include themify($themes) {
                -webkit-text-fill-color: themed('textHeader');
            }
        }
    }

    .customSelect {
        &:-webkit-autofill {
            @include themify($themes) {
                -webkit-text-fill-color: themed('textHeader');
            }
        }

        &:-webkit-autofill:focus {
            @include themify($themes) {
                -webkit-text-fill-color: themed('textHeader');
            }
        }
    }

    select {
        @include themify(($themes)) {
            background-color: themed('background') !important;
        }
    }
}

.dropdown-menu {
    @include themify($themes) {
        color: themed('textDetails');
    }
}

.sectionHr,
.boxBorder {
    @include themify($themes) {
        border-color: themed('headings') !important;
    }
}

.mainlist {
    @include themify($themes) {
        color: themed('ocrTextColor') !important;
    }

    font-size: 15px;
}

.listbg {
    @include themify($themes) {
        border-color: themed('menulist') !important;
    }
}

.whiteBg {
    @include themify($themes) {
        background-color: themed('backgroundSecondary') !important;
    }
}

::placeholder {
    @include themify($themes) {
        color: themed('placeholder') !important;
    }
}

.ocrtext {
    @include themify($themes) {
        color: themed('ocrTextColor') !important;
    }
}

#sidebar-menu>ul>li>a.active {
    @include themify($themes) {
        // border-left: 3px solid themed('headings') !important;
        font-weight: bolder;
    }
}

.table>thead>tr>th {
    &.border-none {
        @include themify($themes) {
            // border: none !important;
            vertical-align: middle;
        }
    }
}

// .table > tbody > tr > th{
//   &.border-none{
//     @include themify($themes) {
//       border: none !important;
//     }
//   }
// }
// .border-none{
//   @include themify($themes) {
//     border: none !important;
//   }
// }
div.user-box,
div.topbar-left {
    @include themify($themes) {
        border-bottom: themed('formBorder') !important;
    }
}

.text-muted,
.action-review,
.aml-achievements-container ul li,
.heading-text,
.content-tabs .tab-item {
    @include themify($themes) {
        color: themed('textDetails') !important;
    }
}

.content-description {
    @include themify($themes) {
        color: themed('textDark') !important;
    }
}

.text-dark {
    @include themify($themes) {
        color: themed('textDark') !important;
    }
}

.text-blue {
    color: #177CDA !important;
}

//Generic Ones
.btn-success.btn-status,
.btn-success.btn-action {
    color: white;
    background: $successColor  !important; //original green;
    // background: #2bbbad !important ; //classy green
    border: 1px solid $successColor;
}

.btn-warning.btn-status,
.btn-warning.btn-action {
    color: white;
    background: $warningColor  !important; //Amber color
    border: 1px solid $warningColor;
}

.btn-danger.btn-status,
.btn-danger.btn-action {
    color: white;
    background: $dangerColor  !important;
    border: 1px solid $dangerColor;
}

.btn-kyc {
    background: #177CDA; //#F94F06;
    border: 1px solid #177CDA; //#F94F06 ;
    color: white !important;
}

.btn {
    overflow: hidden;
    text-overflow: ellipsis;
    // box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12) !important;
    background-color: #177CDA;
    color: white;
}

.btn-corp {
    overflow: hidden;
    text-overflow: ellipsis;
    // box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12) !important;
    background-color: #FBFBFB;
    color: #85868F;
}

.mbtn {
    overflow: hidden;
    text-overflow: ellipsis;
    color: #85868F;
    background-color: #FBFBFB;
}

.dbtn {
    color: #FBFBFB;
    background-color: #00A510;
}

.rbtn {
    border-radius: 16px;
    border-color: none !important;
}

.btn-action {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12) !important;
    opacity: 0.9;
    transition: all ease 0.5s;

    &:hover {
        box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15) !important;
        outline: 0;
        opacity: 1;
    }
}

.text-success {
    color: $successColor  !important;
}

.text-danger {
    color: $dangerColor  !important;
}

.text-warning {
    color: $warningColor  !important;
}

.text-white {
    color: #fff !important;
}

.bg-success,
.badge-success {
    background: $successColor  !important;
}

.bg-danger,
.badge-danger {
    background: $dangerColor  !important;
}

.bg-warning,
.badge-warning {
    background: $warningColor  !important;
}

.dots {
    .dot {
        @include themify($themes) {
            background: themed('backgroundSecondary') !important;
            border-color: themed('backgroundSecondary') !important;
        }
    }

    // .dot:focus {
    //   border: 5px solid #fff;
    // }
}

.badge-grey {
    @include themify($themes) {
        background-color: themed('background') !important;
        color: themed('textLight') !important;
    }
}

.card-box {
    &.widget-user {
        &:hover {
            background-color: #177CDA !important;

            h5,
            p,
            small {
                color: #ffffff !important;
            }

            h5 {
                font-weight: 600px;
            }
        }
    }
}

.horizontal-line {
    @include themify($themes) {
        border-top: themed('lines') !important;
    }
}

.vertical-line {
    @include themify($themes) {
        border-left: themed('lines') !important;
    }
}

//Modal
.modal {
    .modal-dialog {
        .modal-content {
            @include themify($themes) {
                background-color: themed('backgroundSecondary') !important;
            }
        }
    }
}

//modal end
::-webkit-scrollbar-track {
    @include themify($themes) {
        background-color: themed('scrollBg') !important;
    }
}

::-webkit-scrollbar-thumb {
    @include themify($themes) {
        background-color: themed('textDark') !important;
    }
}

.svgBgIcons-light {
    @include themify($themes) {
        background-color: #f2f3f5 !important;
    }
}

.action-svgBgIcons-light {
    @include themify($themes) {
        background-color: themed('sideMenuBg') !important;
    }
}

.svgBgIcons-dark {
    @include themify($themes) {
        background-color: themed('backgroundSecondary') !important;
    }
}

.aml-card-detail-container,.aml-form-container{
    @include themify($themes) {
        border-bottom: themed('cardBorder')
    }
}

.dragAreaBox {
    @include themify($themes) {
        background-color: themed('workflowDragArea') !important;
    }
}

.dropAreaBox {
    @include themify($themes) {
        border: 2px solid themed('workflowDragArea') !important;
    }
}

.top-border{
    @include themify($themes) {
      border-top: themed('topBorder')
    }
  }

.gauge-chart{
    .gauge-chart__label {
      @include themify($themes) {
        color: themed('primaryHeading') !important;
    }
   }  
 }

.entity-image,.light-background{
    @include themify($themes) {
        background-color: themed('backgroundTertiary') !important;
    }
}

.table-status-badge.transparent-bg{
    @include themify($themes) {
        background-color: themed('backgroundTertiary') !important;
        color: themed('textHeader') !important;;
    }
}