@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
$custom: #71b6f9; //lighter blue
$custom: #71b6f9; //lighter blue


// Color variables
$white: #ffffff;
$primary: #188ae2; //light blue
$secondary: #727b84; // mid gray
$success: #00A510; // success green
$info: #35b8e0; //blueish
$warning: #FC9031; // yellowish warning
$danger: #D84040;
$purple: #5b69bc;
$pink: #ff8acc;
$inverse: #3b3e47;
$muted: #3A3A3A;
$light: #eeeeee;
$dark: #435966;
$lightdark3: #333333;
$lightdark4: #444444;
$lightdark5: #555555;
$lightdark7: #797979;
$light5: #ebeff2;
$light3: #f3f3f3;
$light9: #f9f9f9;
$light-alt: #fafafa;
$lightdark-alt: #cccccc;
$lightdark: #f4f8fb;
$lightgray: #7a8c9a;
// Width variables
$width: 100%;
// Height variables
$height: 100%;
// Font size 
$base-font-size: 14px;
// Leftbar background 
$bg-leftbar: #2f3e47;
//Font variables
$font-primary: 'Poppins' !important;
$font-secondary: 'Poppins-Medium' !important;
$font-semi-bold: 'Poppins-Semi-Bold' !important;
/// new color code--->
// light version
$bg-main-light: #e5e5e5;
$primary-card-bg-light: #ffffff;
$primary-heading-light: #212126;
$secondary-heading-light: #343536;
$detail-text-light: #5f6368;
$btn-hover-light: #d2d2d2;
//dark version
$bg-main-dark: #273239;
$primary-card-bg-dark: #41525d;
$primary-heading-dark: #fcfcff;
$secondary-heading-dark: #e6e7f1;
$detail-text-dark: #babccc;
$btn-hover-dark: #273239