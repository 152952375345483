// Detail Page Tabs

.detail-page-tabs {
    display: flex;
    justify-content: space-between;
    overflow-x: auto;
    scroll-behavior: smooth;
    align-items: center;
    flex-wrap: unset !important;

    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }

    .tab-item {
        cursor: pointer;
        background: transparent;
        font-family: $font-secondary;
        font-size: 16px;
        line-height: 25.6px;
        letter-spacing: -0.32px;
        margin-right: 24px;
        white-space: nowrap;
        height: 48px;
        display: flex;
        align-items: center;
    }
}