.customInput {
    position: relative;
    max-width: 448px;
    min-width: 256px;
    width: 100%;
    margin-bottom: 24px !important;

    &__label {
        position: absolute;
        left: 5px;
        top: 3px;
        padding: 8px 18px; //calc(var(--size-bezel) * 0.75) calc(var(--size-bezel) * .5);
        margin: calc(var(--size-bezel) * 0.75 + 3px) calc(var(--size-bezel) * .5);
        background: pink;
        white-space: nowrap;
        transform: translate(0, 0);
        transform-origin: 0 0;
        transition: transform 120ms ease-in;
        font-family: "Poppins";
        font-size: 16px;
        line-height: 25.6px;
        letter-spacing: -0.32px;
    }

    &__field {
        box-sizing: border-box;
        display: block;
        border-radius: 12px !important;
        width: 100%;
        padding: 12px 20px; //calc(var(--size-bezel) * 1.5) var(--size-bezel);
        background: transparent;
        border-radius: var(--size-radius);
        height: 48px;
        font-family: "Poppins-Medium";
        font-size: 16px;
        line-height: 25.6px;
        letter-spacing: -0.32px;

        &:focus,
        &:not(:placeholder-shown) {
            &+.customInput__label {
                transform: translate(0.75rem, -45%) scale(0.9);
                padding: 0px 20px;
                padding-left: 9px;
                top: 0
            }
        }

        &:hover {
            box-shadow: 0px 1px 6px 2px rgba(0, 0, 0, 0.07);
        }
    }

    .icon-label {
        transform: translate(0.75rem, -45%) scale(0.9);
        padding: 0px 20px;
        padding-left: 9px;
        top: 0
    }
}


// Text Area

.customTextArea{
    margin-bottom: 24px !important;

    textarea{
        width: 100%;
        min-height: 120px;
        height: 48px;
        font-family: "Poppins-Medium";
        font-size: 16px;
        line-height: 25.6px;
        letter-spacing: -0.32px;
        border-radius: 12px !important;
        padding: 12px 20px;
        resize: none;
        background-color: transparent;
    }
}

// checkbox
// Use class 'customCheckbox' if there is no label text and if there is label text, please use 'customCheckbox label'

.customCheckbox {
    display: block;
}

.customCheckbox input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
}

.customCheckbox label {
    position: relative;
    cursor: pointer;
}

.customCheckbox label:before {
    content: '';
    -webkit-appearance: none;
    background-color: transparent;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 12px;
    border-radius: 4px;
}

.customCheckbox input:checked+label:before {
    border: 2px solid #177CDA;
}

.customCheckbox input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 9px;
    width: 6px;
    height: 13px;
    border: solid #177CDA;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.customCheckbox.label input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 6px;
    left: 9px;
    width: 6px;
    height: 13px;
    border: solid #177CDA;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}