@import '_variables.scss';

// Text Styles

h1,
h2,
h3,
h4,
h5,
h6,
p,
label {
    margin: 0 !important;
}

.primary-heading {
    // Use h5 tag
    font-family: $font-semi-bold;
    font-size: 24px;
    line-height: 38.4px;
    letter-spacing: -0.48px;
}

.secondary-heading {
    // Use h5 tag
    font-family: $font-semi-bold;
    font-size: 18px;
    line-height: 28.8px;
    letter-spacing: -0.36px;
}

.text-header,
.processLength span {
    // Use P tag
    font-family: $font-secondary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
}

.text-details,
.tags,
.text-muted {
    // Use P tag
    font-family: $font-primary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
}

.text-snippet {
    // Use P tag
    font-family: $font-primary;
    font-size: 12px;
    line-height: 19.2px;
    letter-spacing: -0.24px;
}

.text-defaults {
    // For Default text
    font-family: $font-primary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
}

.highlight-text {
    color: #177CDA !important;
    font-family: $font-primary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
}

.text-dropdownOption {
    // For Dropdown Option Text
    font-family: $font-primary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
}

.validation-text {
    // For Validation
    font-family: $font-primary;
    font-size: 12px;
    line-height: 19.2px;
    letter-spacing: -0.24px;
    color: #ED7D15 !important;
    margin: 3px 0px 10px 20px;
}

.kyc-text-danger {
    // For Danger text
    font-family: $font-primary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
    color: #D84040 !important;
}

.kyc-text-success {
    // For Success text
    font-family: $font-primary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
    color: #0DB9A4 !important;
}

.kyc-text-warning {
    // For Warning text
    font-family: $font-primary;
    font-size: 16px;
    line-height: 25.6px;
    letter-spacing: -0.32px;
    color: #ED7D15 !important;
}